import { useUniversalRouter } from '@finn/auto-ui/modules/checkout/hooks/useCheckoutHelpers';
import { Button } from '@finn/design-system/atoms/button';
import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { useIntl } from '@finn/ui-utils';
import React, { memo, useCallback } from 'react';

type Props = {
  vehicle?: GenericVehicleDetails;
  onClose(): void;
};

const NotifySuccess: React.FC<Props> = ({ vehicle, onClose }) => {
  const i18n = useIntl();

  const router = useUniversalRouter();

  const handleCtaClick = useCallback(() => {
    onClose();
    router.push(`/${BASE_SUBSCRIPTION_PATH}`);
  }, [onClose, router]);

  const title = i18n.formatMessage(
    `general.notifyMeModal.comingSoon.successTitle`
  );

  const description = i18n.formatMessage(
    `general.notifyMeModal.comingSoon.successDescription`,
    {
      location: null,
      car: vehicle ? `${vehicle.brand?.id} ${vehicle.model}` : '',
    }
  );

  const ctaLabel = i18n.formatMessage(
    `general.notifyMeModal.comingSoon.successCtaLabel`
  );

  return (
    <>
      <h3 className="mobile-t3-semibold md:web-t3-semibold mb-8">{title}</h3>

      <p className="body-16-light mb-8">{description}</p>

      <div>
        <Button data-cy="browse-more" onClick={handleCtaClick}>
          {ctaLabel}
        </Button>
      </div>
    </>
  );
};

export default memo(NotifySuccess);
