import { CheckoutStep } from '@finn/ua-auth';
import { RESTORE_DEDUPING_INTERVAL, useFetchProduct } from '@finn/ua-vehicle';
import { useCurrentLocale } from '@finn/ui-utils';
import useSWR from 'swr';

import { restoreProcessFetcher } from '~/modules/checkout/api/restoreProcessFetcher';
import useIsE2ETest from '~/modules/checkout/hooks/useIsE2ETest';
import { getRestoreCacheValue } from '~/modules/checkout/utils/restoreCache';
import { CartDealInfo, DealInfo, RestoredData } from '~/types/checkout';

import { useCheckoutParams, useUniversalRouter } from './useCheckoutHelpers';

export const useGetDealData = (origin?: CheckoutStep, dealInfo?: DealInfo) => {
  const router = useUniversalRouter();
  const { locale } = useCurrentLocale();
  const isE2ETest = useIsE2ETest();
  const restoreCacheValue = getRestoreCacheValue();
  let [contactId, dealId, hash] = useCheckoutParams();
  if (dealInfo) {
    contactId = String(dealInfo.contactId);
    dealId = String(dealInfo.dealId);
    hash = String(dealInfo.hash);
  }

  const isCart = origin === CheckoutStep.CART;

  const {
    data: restoredData,
    isLoading: isLoadingRestore,
    error,
  } = useSWR<RestoredData>(
    origin && !isCart && router.isReady
      ? {
          dealInfo: { contactId, dealId, hash },
          checkoutRequestOptions: { isProxy: true },
          locale,
          isE2ETest,
          restoreCacheKey: restoreCacheValue, // value not used, it's just to set different cache keys after form submission
        }
      : null,
    restoreProcessFetcher,
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: RESTORE_DEDUPING_INTERVAL,
    }
  );

  if (error) {
    console.error({ message: error, category: 'SWR Restore Api Call - Deal' });
  }

  const vehicleId = restoredData?.cart?.vehicleId;
  const kilometerPackage = restoredData?.cart?.kilometerPackage;
  const term = restoredData?.cart?.term;

  const vehicleParams = {
    vehicleId,
  };

  const {
    data: vehicle,
    isLoading: isLoadingVehicle,
    error: vehicleFetchError,
  } = useFetchProduct(vehicleParams);

  if (vehicleFetchError) {
    console.error({
      message: vehicleFetchError,
      category: 'SWR Vehicle Api Call - Deal',
    });
  }

  const isLoading = isLoadingVehicle || isLoadingRestore;
  const isLoadingEssential = isLoading; // all are essential

  const cartDealInfo: CartDealInfo = {
    email: restoredData?.cart?.email,
    vehicleId,
    term: Number(term),
    kilometerPackage: Number(kilometerPackage),
  };

  const isValidPath = !router.isReady || Boolean(contactId && dealId && hash);

  if (restoredData?.contact) {
    restoredData.contact = {
      ...restoredData.contact,
      contactId: Number(contactId),
      dealId: Number(dealId),
      hash,
    };
  }

  return {
    vehicle,
    cartDealInfo,
    isValidPath,
    restoredData,
    isLoading,
    isLoadingEssential,
  };
};
