import { SWR_CACHE_TIME } from '@finn/ua-constants';
import { useCurrentLocale } from '@finn/ui-utils';
import useSWR from 'swr';

import { GenericVehicleDetails } from '../../product-cards';
import { dynamicFetcher } from '../data/fetchers';
import { useDiscoveryConfig } from '../data/MetadataProvider';

export type GetVehicleParams = {
  vehicleId?: string;
  locale?: string;
  zipCode?: string;
  cug_id?: string;
  hide_related?: boolean;
  query?: Record<string, string>;
};

export const useFetchProduct = (
  params: GetVehicleParams,
  fallbackData?: GenericVehicleDetails
) => {
  const { locale } = useCurrentLocale();

  const isLocaleProvided = 'locale' in params;

  const requestParams = {
    ...params,
    hide_related: true,
    zipCode: undefined,
    locale: isLocaleProvided ? params.locale : locale,
  };

  const { vehicleId } = requestParams;
  const config = useDiscoveryConfig();

  return useSWR<GenericVehicleDetails>(
    vehicleId
      ? config.apiFetchers.getVehicleConfig({
          vehicleId,
          locale,
          query: params.query,
        })
      : null,
    dynamicFetcher,
    {
      fallbackData,
      dedupingInterval: SWR_CACHE_TIME,
    }
  );
};
