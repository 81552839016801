import { cn } from '@finn/ui-utils';
import {
  Arrow,
  Content,
  Portal,
  Provider,
  Root,
  Trigger,
} from '@radix-ui/react-tooltip';
import {
  ComponentProps,
  createContext,
  Dispatch,
  ElementRef,
  forwardRef,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';

import { useHasHover } from '../../helpers/media';

// we use this context based on https://github.com/radix-ui/primitives/issues/1573
// which is a workaround to make radix tooltip work on touch devices
// more about motivation  of radix can be found here https://github.com/radix-ui/primitives/issues/955#issuecomment-960610209
// and you can also checjk w3.org as primary source of motivation for radix https://www.w3.org/WAI/ARIA/apg/patterns/tooltip/
// we decided to still support tooltip on touch devices, as we did it before with MUI
// and our Design System is assume such behaviour
type TooltipTriggerContextType = {
  open: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
};

const TooltipTriggerContext = createContext<TooltipTriggerContextType>({
  open: false,
});

export const TooltipTrigger = forwardRef<
  ElementRef<typeof Trigger>,
  ComponentProps<typeof Trigger> & { testId?: string }
>(({ children, testId }, forwardedRef) => {
  const hasHover = useHasHover();
  const { setOpen } = useContext(TooltipTriggerContext);
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!hasHover) {
        e?.preventDefault();
        setOpen?.(true);
      }
    },
    [hasHover, setOpen]
  );

  return (
    <Trigger
      className="outline-transparent"
      tabIndex={0}
      asChild
      onClick={handleClick}
      ref={forwardedRef}
      type="button"
      data-testid={testId}
    >
      {children}
    </Trigger>
  );
});

type TooltipContentProps = { title?: string; body?: string | ReactNode };

export const TooltipContent = forwardRef<
  ElementRef<typeof Content> & TooltipContentProps,
  ComponentProps<typeof Content> & TooltipContentProps
>(({ title, body, children, className, ...props }, ref) => (
  <Portal>
    <Content
      ref={ref}
      className={cn('z-popover max-w-80 rounded bg-black p-4', className)}
      sideOffset={2}
      {...props}
    >
      {title && <p className="body-14-semibold mb-4 text-white">{title}</p>}
      {body && <span className="body-14-light text-white">{body}</span>}
      {children}
      <Arrow className="h-[6px] w-[14px] fill-black" />
    </Content>
  </Portal>
));

type TooltipProps = {
  children: ReactNode;
} & ComponentProps<typeof Root>;

// we use 100, as before we used MUI, which had a built in delay of 100
const TOOLTIP_SHOW_DELAY = 100;

export const Tooltip = ({ children, onOpenChange, ...props }: TooltipProps) => {
  const [open, setOpen] = useState(props.defaultOpen ?? false);
  const hasHover = useHasHover();

  const handleOpenChange = useCallback(
    (opened: boolean) => {
      onOpenChange?.(opened);
      setOpen(opened);
    },
    [onOpenChange]
  );

  return (
    <Provider delayDuration={hasHover ? TOOLTIP_SHOW_DELAY : 0}>
      <TooltipTriggerContext.Provider value={{ open, setOpen }}>
        <Root {...props} open={open} onOpenChange={handleOpenChange}>
          {children}
        </Root>
      </TooltipTriggerContext.Provider>
    </Provider>
  );
};
