import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';

import { HandoverDates, SwapHandoverOption } from '~/services/handover';
import { dayjsDate } from '~/types/time';

dayjs.extend(isBetween);
dayjs.extend(CustomParseFormat);

export const isBusinessDay = (date: dayjsDate) => {
  const workingWeekdays = [1, 2, 3, 4, 5];
  if (workingWeekdays.includes(dayjs(date).day())) return true;

  return false;
};

export const isWeekend = (date: dayjsDate) => {
  return !isBusinessDay(date);
};

export const reverseAbilipayDate = (date: string) =>
  dayjs(date, 'DD.MM.YYYY').toDate();

export const getKeyDates = (
  carHandoverAvailability?: HandoverDates,
  swapHandoverOption?: SwapHandoverOption | null
): {
  minDate: string | undefined;
  maxDate: string | undefined;
  freeDeliveryDate: string | undefined;
  closestSwapDate: string | undefined;
} => {
  if (!carHandoverAvailability) return null;
  let minDate = new Date();
  let maxDate = new Date();
  const availableDates = Object.keys(carHandoverAvailability).map((date) =>
    dayjs(date).toDate().getTime()
  );
  if (availableDates.length) {
    const earliestAvailableDate = new Date(
      Math.min.apply(null, availableDates)
    );
    const latestAvailableDate = new Date(Math.max.apply(null, availableDates));

    minDate = earliestAvailableDate;
    maxDate = latestAvailableDate;
  }

  const freeDateObj = Object.entries(carHandoverAvailability).find(
    (item) => item?.[1]?.delivery_fee === 0
  );

  const freeDeliveryDate = freeDateObj
    ? dayjs(freeDateObj[0]).toDate()
    : undefined;

  let closestSwapDate = undefined;

  if (swapHandoverOption?.subscription_end_date) {
    const subscriptionEndDate = dayjs(swapHandoverOption.subscription_end_date)
      .add(6, 'hour') // add bias towards next day instead of previous day while picking the closest date
      .toDate()
      .getTime();

    const closestSwapDateTimestamp = availableDates.reduce((prev, current) =>
      Math.abs(prev - subscriptionEndDate) <
      Math.abs(current - subscriptionEndDate)
        ? prev
        : current
    );

    closestSwapDate = closestSwapDateTimestamp
      ? new Date(closestSwapDateTimestamp)
      : undefined;
  }

  return {
    minDate: minDate?.toISOString(),
    maxDate: maxDate?.toISOString(),
    freeDeliveryDate: freeDeliveryDate?.toISOString(),
    closestSwapDate: closestSwapDate?.toISOString(),
  };
};

export const isWithinRange = (date: Date, start: Date, end: Date) => {
  return date >= start && date <= end;
};

export const addBusinessDays = (date: dayjsDate, number: number) => {
  const numericDirection = number < 0 ? -1 : 1;
  let currentDay = dayjs(date);
  let daysRemaining = Math.abs(number);

  while (daysRemaining > 0) {
    currentDay = currentDay.add(numericDirection, 'd');

    if (isBusinessDay(currentDay)) daysRemaining -= 1;
  }

  return currentDay.toDate();
};

export const isDateBeforeMinDate = ({
  date,
  minDate,
}: {
  date: string;
  minDate: string;
}) => {
  let diff = 0;
  try {
    // diff in hours. For timezone issue we get 2 hours diff for the same date
    diff = (dayjs(minDate).valueOf() - dayjs(date).valueOf()) / 3600000;
  } catch {
    // do nothing
  }

  // if diff is more than 12 hours, we consider it as different date
  return diff > 12;
};

export const getReversedDate = (
  date: string | undefined,
  propertyName: string
) => {
  return date ? { [propertyName]: reverseAbilipayDate(date) } : {};
};
