import { NextRequest } from 'next/server';

export function stringifyParams(objParams: object): Record<string, string> {
  return { maps: JSON.stringify(objParams) };
}

export function parseParams<T>(req: NextRequest): T {
  const searchParams = req.nextUrl.searchParams;
  const maps = searchParams.get('maps');

  return JSON.parse(maps as string);
}
