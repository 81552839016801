import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { useContext } from 'react';

import CheckoutContext from '~/contexts/Checkout';

import { useUniversalRouter } from './useCheckoutHelpers';

// when called from outside of checkout context, we need to send isCartExt = true if it's cart
export const useGetDownPaymentInCart = (
  isCartExt = false,
  vehicleFallback: GenericVehicleDetails | undefined = undefined // optionally needed when called from outside the checkout context
) => {
  const { isCart, vehicle } = useContext(CheckoutContext);
  const resolvedVehicle = vehicle || vehicleFallback;
  const router = useUniversalRouter();
  const downPaymentAmountInUrl = router.query.downPaymentAmount as string;
  const downPaymentAmountInVehicle =
    resolvedVehicle?.downpayment_prices?.downpayment_fixed_amount;
  const downPaymentAmountMatches =
    Number(downPaymentAmountInVehicle) === Number(downPaymentAmountInUrl);
  const hasValidDownPaymentInCartUrl =
    (isCart || isCartExt) && downPaymentAmountMatches;

  return {
    hasValidDownPaymentInCartUrl,
    downPaymentAmountInCart: hasValidDownPaymentInCartUrl
      ? downPaymentAmountInVehicle
      : undefined,
  };
};
