import { BaseCosmicData, CosmicMetafield, FearAwayData } from '@finn/ui-cosmic';
import { ApiResponse } from '@finn/ui-utils';
import { createContext, ReactNode } from 'react';

import { FirstVehicleImageAsBase64, GenericVehicleDetails } from '../types';

export type Review = {
  id: number;
  brand: string;
  comment: string;
  create_date: number; // unix timestamp
  full_name: string;
  model: string;
  score: number;
  source: string;
};

export type OneTimePrice = { duration: number; price: number };
export type MonthlyPrice = {
  distance: number;
  price: number;
  duration: number;
};

export type ListType = BaseCosmicData & {
  metafields: CosmicMetafield[];
  bucket: string;
  created_at: string;
  created_by: string;
  modified_at: string;
  created: string;
  published_at: string;
  modified_by: string;
  publish_at?: string;
};

type Vehicle = {
  vehicle: GenericVehicleDetails;
  monthlyPrice: MonthlyPrice;
  /** This naming is not accurate. This value represents the monthly base price
   * based on a fixed subscription length (aka "subscription term"). E.g., 800
   * EUR per month based on a 6 months subscription */
  monthlyPriceWithFees: number;
  oneTimePrice: OneTimePrice;
  setOneTimePrice: (value: OneTimePrice) => void;
  setMonthlyPrice: (value: MonthlyPrice) => void;
  fearAwayItems: FearAwayData[];
  firstVehicleImageAsBase64?: FirstVehicleImageAsBase64;
  term?: number;
  kmPackage?: number;
  reviews?: ApiResponse<Review>;
};

export const VehicleContext = createContext<Vehicle>({} as Vehicle);
export type VehicleContextType = Vehicle;

type Props = {
  vehicle: GenericVehicleDetails;
  fearAwayItems: FearAwayData[];
  reviews?: ApiResponse<Review>;
  children: ReactNode;
  monthlyPrice?: MonthlyPrice;
  oneTimePrice?: OneTimePrice;
};

export const VehicleProvider = ({
  children,
  vehicle,
  fearAwayItems,
  reviews,
  monthlyPrice,
  oneTimePrice,
}: Props) => {
  const contextValues = {
    vehicle,
    fearAwayItems,
    reviews,
    // B2B using these values, we're using the ConfigureStore
    monthlyPrice: monthlyPrice || {
      distance: 0,
      price: 0,
      duration: 0,
    },
    monthlyPriceWithFees: 0,
    oneTimePrice: oneTimePrice || {
      duration: 0,
      price: 0,
    },
    setOneTimePrice: () => {},
    setMonthlyPrice: () => {},
  };

  return (
    <VehicleContext.Provider value={contextValues}>
      {children}
    </VehicleContext.Provider>
  );
};
