import { cn } from '@finn/ui-utils';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import React from 'react';

export type ToggleGroupProps = Omit<
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root>,
  'type' | 'value' | 'defaultValue' | 'onValueChange'
> & {
  value?: string;
  cols?: 1 | 2 | 3;
  defaultValue?: string;
  onValueChange?: (value: string) => void;
};

const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  ToggleGroupProps
>(({ className, children, cols = 2, ...props }, ref) => {
  return (
    <ToggleGroupPrimitive.Root
      ref={ref}
      className={cn(
        'grid gap-2',
        {
          'grid-cols-3': cols === 3,
          'grid-cols-2': cols === 2,
          'grid-cols-1': cols === 1,
        },
        className
      )}
      type="single"
      {...props}
    >
      {children}
    </ToggleGroupPrimitive.Root>
  );
});

ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName;

export { ToggleGroup };
