'use client';
import { Button, ButtonProps } from '@finn/design-system/atoms/button';
import React, { useState } from 'react';

type ActionaButtonProps = ButtonProps & {
  label: string;
  IconLeft?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const ActionButton = ({
  onClick,
  label,
  IconLeft,
  ...rest
}: ActionaButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async (
    event: React.MouseEvent<HTMLAnchorElement> &
      React.MouseEvent<HTMLButtonElement>
  ) => {
    setLoading(true);
    if (onClick) {
      await onClick(event);
    }
    setLoading(false);
  };

  return (
    <Button size="lg" loading={loading} onClick={handleClick} {...rest}>
      {IconLeft ? <IconLeft /> : null}
      {label}
    </Button>
  );
};
