import { IntlShape } from '@finn/ui-utils';

import { USState } from '~/modules/location/constants';
import { ClientType, OrganizationType } from '~/types/checkout';

export const clientTypes = [
  { value: ClientType.PRIVATE, label: 'Privatkunde' },
  { value: ClientType.BUSINESS, label: 'Geschäftskunde' },
];

export const USStatesOptions = [
  { value: USState.PA, label: 'Pennsylvania' },
  { value: USState.NJ, label: 'New Jersey' },
  { value: USState.CT, label: 'Connecticut' },
  { value: USState.MA, label: 'Massachusetts' },
  { value: USState.VA, label: 'Virginia' },
  { value: USState.MD, label: 'Maryland' },
  { value: USState.DC, label: 'Washington, DC' },
  { value: USState.NH, label: 'New Hampshire' },
  { value: USState.VT, label: 'Vermont' },
  { value: USState.ME, label: 'Maine' },
  { value: USState.RI, label: 'Rhode Island' },
  { value: USState.NY, label: 'New York' },
];

export const checkIfStateEnabled = (state: string) => {
  return USStatesOptions.map((item) => item.value).includes(state as USState);
};

export const fleetSizeOptions = [
  { label: 'Flottengröße 1', value: 1 },
  { label: 'Flottengröße 2-3', value: 2 },
  { label: 'Flottengröße 4-10', value: 4 },
  { label: 'Flottengröße 10-20', value: 10 },
  { label: 'Flottengröße 20+', value: 20 },
  { label: 'Ich bin unsicher bzgl. der Flottengröße.', value: -1 },
];

export const getOrganizationTypeOptions = (i18n: IntlShape) => {
  const prefix = 'checkout.pageData.organization_type';

  const organizationTypeOptions = [
    {
      label: i18n.formatMessage(`${prefix}.gmbh`),
      value: OrganizationType.GMBH,
    },
    {
      label: i18n.formatMessage(`${prefix}.eingetragene_einzelfirma`),
      value: OrganizationType.EINGETRAGENER_EINZELFIRMA,
    },
    {
      label: i18n.formatMessage(`${prefix}.kein_handelsregistereintrag`),
      value: OrganizationType.KEIN_HANDELSREGISTEREINTRAG,
    },
    {
      label: i18n.formatMessage(`${prefix}.aktiengesellschaft`),
      value: OrganizationType.AKTIENGESELLSCHAFT,
    },
    {
      label: i18n.formatMessage(`${prefix}.bgbgesellschaft_gbr`),
      value: OrganizationType.BGB_GESELLSCHAFT,
    },
    {
      label: i18n.formatMessage(`${prefix}.genossenschaft`),
      value: OrganizationType.GENOSSENSCHAFT,
    },
    {
      label: i18n.formatMessage(`${prefix}.gmbh__cokg`),
      value: OrganizationType.GMBH_COKG,
    },
    {
      label: i18n.formatMessage(`${prefix}.kg`),
      value: OrganizationType.KG,
    },
    {
      label: i18n.formatMessage(`${prefix}.eingetragener_verein`),
      value: OrganizationType.EINGETRAGENER_VEREIN,
    },
    {
      label: i18n.formatMessage(`${prefix}.stiftung`),
      value: OrganizationType.STIFTUNG,
    },
  ];

  return organizationTypeOptions;
};
